.investmentobj-content-container {
    display: flex;
}

.investmentobj-img-wrapper {
    width: 40%
}

.investmentobj-content-wrapper {
    max-width: 60%;
    display: flex;
    flex-flow: column;
}

.investmentobj-card {
    display: flex;
}

.investmentobj-card-content-wrapper {
    max-width: 685px
}

.investmentobj-card-img-wrapper {
    margin-right: 30px;
}

.investmentobj-card-img-wrapper img {
    width: 50px;
}

.investmentobj-card h2 {
    margin-top: 0;
    font-weight: 900;
    font-size: 30px;
    line-height: 37px;
    color: #414042;
    text-transform: uppercase;
}

.investmentobj-card p {
    font-weight: 400;
    font-size: 20px;
    line-height: 2;
    color: #777777;
    letter-spacing: 2px
}

@media only screen and (max-width: 1200px) {
    .investmentobj-img-wrapper img {
        width: 85%;
        padding: 0 20px;
    }

    .investmentobj-img-wrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .investmentobj-content-wrapper {
        margin-top: 50px;
        width: 100%;
        max-width: 100%;
    }

    .investmentobj-img-wrapper img {
        width: 55%;
    }

    .investmentobj-content-container {
        flex-flow: column
    }
}

@media only screen and (max-width: 650px) {
    .investmentobj-img-wrapper img {
        width: 100%;
    }
}