.hero_container {
  height: 100vh;
  overflow: hidden;
}

.hero_content_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

#hero_video {
  min-height: 100%;
}

.hero-section-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-section-2-wrapper {
  display: flex;
  justify-content: space-evenly;
}

.hero-section-2-box-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}

.hero-section-2-box-wrapper img {
  width: 60px;
  padding-bottom: 10px;
}

.thumbnail-wrapper {
  width: 400px;
}

.thumbnail-wrapper img {
  width: 100%;
}

.hero-arrow-container {
  position: absolute;
  bottom: 5%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}


.hero-arrow-show {
  animation: bounce 2s ease infinite;
  display: inline-block;
  color: white;
  transition: color 0.5s ease 0s;
}

.hero-arrow-hide {
  animation: bounce 2s ease infinite;
  display: inline-block;
  color: transparent;
  transition: color 0.5s ease 0s;
}

.svg-inline--fa {
  height: 2em;
}

.svg-inline--fa.fa-w-14 {
  width: 2em;
}

@keyframes bounce {

  0%,
  25%,
  55%,
  75%,
  100% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(-20px);
  }

  65% {
    transform: translateY(-10px);
  }

  85% {
    transform: translateY(-5px);
  }
}

@media only screen and (min-width: 1920px) {
  #hero_video {
    width: 100%;
  }
}

@media only screen and (max-width: 765px) {
  .hero-section-wrapper {
    flex-flow: column
  }

  .thumbnail-wrapper {
    width: 100%;
  }

  .hero-section-2-wrapper {
    flex-flow: column
  }

  .hero-section-2-box-wrapper img {
    width: 80px !important;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}