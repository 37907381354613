.whatis-content-wrapper {
    display: flex;
    justify-content: space-between;
}

.whatis-info-wrapper {
    width: 50%;
}

.whatis-video-wrapper {
    width: 50%;
}

.whatis-video-wrapper img {
    width: 500px;
    padding: 0 15px;
}

@media only screen and (max-width: 1000px) {
    .whatis-content-wrapper {
        flex-flow: column
    }

    .whatis-info-wrapper {
        width: 100%;
    }

    .whatis-video-wrapper {
        width: 80%;
        margin: auto;
    }

    .whatis-video-wrapper img {
        padding: 15px;
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .whatis-video-wrapper {
        width: 100%;
        margin: auto;
    }

    .whatis-video-wrapper img {
        padding: 15px 0;
    }
}