.alotofdata-content-container {
    display: flex;
    margin-bottom: 30px;
}

.alotofdata-info-wrapper {
    width: 50%
}

.alotofdata-img-wrapper {
    width: 50%;
    padding-left: 20px;
}

.alotofdata-img-wrapper img {
    width: 80%
}

@media only screen and (max-width: 1000px) {
    .alotofdata-content-container {
        flex-flow: column;
        margin-bottom: 30px;
    }

    .alotofdata-img-wrapper {
        width: 75%;
        margin: auto;
        margin-top: 20px;
    }

    .alotofdata-img-wrapper img {
        width: 100%
    }

    .alotofdata-info-wrapper {
        width: 100%
    }
}

@media only screen and (max-width: 650px) {
    .alotofdata-img-wrapper {
        width: 100%;
    }
}