/* The Modal (background) */
.modal-show {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 16;
    /* Sit on top */
    padding-top: 5rem;
    padding-bottom: 5rem;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.modal-hide {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.modal-content-cust {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    /* border: 1px solid #888; */
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 7px;
}

.modal-video-mod {
    width: 100%;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

/* The Close Button */
.modal-close-cust {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.modal-close-cust:hover,
.modal-close-cust:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header-cust {
    padding: 2px 16px;
    background-color: #49a547;
    color: white;
    border-radius: 5px 5px 0 0;
    text-align: center;
}

.modal-header-cust h5 {
    color: white;
    margin-bottom: 0;
    padding: 10px;
}

.modal-body-cust {
    padding: 10px;
}

@media only screen and (max-width: 850px) {
    .modal-content-cust {
        width: 98%;
    }
}