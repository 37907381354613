.footer-main-container {
    padding-bottom: 40px;
}

.footer-main-container h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: #7f7f7f
}

.footer-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    margin-bottom: 40px;
}

.sc-logo {
    margin-left: 30px;
    padding: 10px 0;
}

@media only screen and (max-width: 975px) {
    .footer-flex {
        flex-flow: column;
        align-items: flex-start;
    }
}