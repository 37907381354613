.fg-content-container {
    display: flex;
    margin-bottom: 20px;
}

.fg-image-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 35px;
}

.fg-image-wrapper img {
    width: 50%;
    height: 50%;
}

.fg-top-img {
    width: 60%;
}

@media only screen and (max-width: 1200px) {
    .fg-image-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 35px;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    .fg-image-wrapper img {
        width: 100%;
        height: 100%;
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 875px) {
    .fg-content-container {
        flex-flow: column;
    }

    .fg-content-container p {
        width: 100%
    }

    .fg-top-img-wrapper {
        width: 100%;
    }

    .fg-top-img {
        width: 75%;
    }
}

@media only screen and (max-width: 650px) {
    .fg-top-img {
        width: 100%;
    }
}