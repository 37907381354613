/* CARD STYLES START */
.asset-card {
    box-shadow: 0 4px 8px 0 hsl(0deg 0% 100% / 40%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    text-align: left;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    width: 490px;
    margin: 15px;
}

.asset-card-img-top {
    max-height: 325px;
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.asset-card-body {
    height: 400px;
    overflow: auto;
    padding: 1.25rem;
}

.asset-card-body h4 {
    font-size: 20px;
    margin: 0;
}

.asset-card-title {}

.asset-card-location {
    font-weight: 600;
}

.asset-card-offering-price {
    font-weight: 600;
}

.investment-details {
    text-align: right;
    display: block;
    bottom: 20px;
    right: 20px;
    font-weight: 700;
}

.asset-card-info-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

.carousel-card-inner-wrapper {
    width: 90%;
    margin: auto;
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-bottom: 40px;
}

.carousel-control-next,
.carousel-control-prev {
    width: 5% !important;
}

.investment-details {
    bottom: 20px;
    right: 20px;
    font-weight: 600;
    text-decoration: none;
    color: #49a547
}

.investment-details:hover {
    color: #95c93d;
    text-decoration: none;
}

/* CARD STYLES END */