.howweuse-content-container {
    display: flex;
    align-items: center;
}

.big-text {
    font-weight: 700;
    font-size: 27px;
}

.howweuse-content-wrapper {
    width: 50%;
    text-align: center;
}

.howweuse-img-wrapper {
    width: 50%;
    text-align: center;
}

@media only screen and ( max-width: 850px ) {
    .howweuse-content-container {
        flex-flow: column;
    }
    .howweuse-img-wrapper {
        width: 100%;
    }
    .howweuse-content-wrapper {
        width: 100%;
    }
}

@media only screen and ( max-width: 520px ) {
    .howweuse-content-container {
        flex-flow: column;
    }
    .howweuse-img-wrapper img {
        width: 100%;
    }
}