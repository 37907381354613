.nav-main-wrapper-clear {
  position: fixed;
  width: 100%;
  z-index: 10;
  transition: background-color 0.5s ease 0s;
}

.nav-main-wrapper-white {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: white;
  transition: background-color 0.5s ease 0s;
  box-shadow: 0 8px 6px -6px #777777;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px;
}

.nav-link-ul {
  list-style-type: none;
  margin-top: 16px;
}

.mobile-ul {
  list-style-type: none;
  padding-left: 70px;
}

.mobile-ul li {
  padding: 5px 0;
}

.mobile-ul a {
  text-decoration: none;
  text-transform: uppercase;
}

.mobile-ul a:hover {
  color: #95c93d;
  text-decoration: none;
}

.nav-link-ul a {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
}

.nav-link-ul a:hover {
  color: #95c93d;
  text-decoration: none;
}

.nav-link-ul li {
  display: inline;
  padding: 0 10px;
}

.nav-logo:hover {
  cursor: pointer;
}

.navbar-mobile-hamburger:hover {
  cursor: pointer;
}

.nav-dropdown-show-white {
  width: 100%;
  background: white;
  position: absolute;
  padding-top: 305px;
  z-index: -1;
  left: 0;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.nav-dropdown-show-clear {
  width: 100%;
  background: transparent;
  position: absolute;
  margin-top: 305px;
  left: 0;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.navbar-navline-gray {
  width: 35px;
  height: 5px;
  background-color: #414042;
  margin: 6px 0;
}

.navbar-navline-white {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
}

.nav-dropdown-hide {
  display: none;
}

.closing-banner-nav {
  background: rgb(218, 10, 10);
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin: 0 10px;
}

@media only screen and (max-width: 1065px) {
  .nav-links-container {
    display: none;
  }

  .navbar-container-clear {
    justify-content: space-between;
  }

  .navbar-container-black {
    justify-content: space-between;
  }

  .navbar-mobile-hamburger {
    margin-right: 20px;
  }

  .navbar-logo-container {
    margin-left: 20px;
  }

  .navbar-nav-items {
    color: white;
  }
}

@media only screen and (min-width: 1066px) {
  .nav-dropdown-show {
    display: none;
  }

  .nav-dropdown-hide {
    display: none;
  }

  .navbar-mobile-hamburger {
    display: none;
  }

  .nav-dropdown-show-clear {
    display: none;
  }

  .nav-dropdown-show-white {
    display: none;
  }
}

@media only screen and (max-width: 772px) {
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
  }
  .mobile-ul {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 515px) {
  .nav-logo {
    width: 130px;
  }
  .closing-banner-nav {
    font-size: 12px;
  }
  .nav-dropdown-show-white {
    padding-top: 279px;
  }
}

/* CLOSING BAR */
.closing-bar-show {
  background: rgb(218, 10, 10);
  color: white;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.closing-bar-hide {
  display: none;
}

.banner-close-button {
  position: absolute;
  right: 0;
  margin-right: 15px;
  padding-left: 10px;
}

.banner-close-button:hover {
  cursor: pointer;
}
