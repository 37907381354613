.startinvesting-content-container {
    margin-top: 100px;
}

.startinvesting-content-container h2 {
    font-size: 26px;
}

.startinvesting-p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.startinvesting-img {
    padding: 20px 0;
}