.content_header {
    max-width: 1220px;
    margin: auto;
    font-size: 2.5rem;
    font-weight: 200;
    margin-bottom: 4rem;
    /* border-bottom: 1px solid #c1c1c1; */
    padding-bottom: 8px;
    color: #545454;
    position: relative;
    padding: 0 15px;
}

.content_header::after {
    position: absolute;
    content: "";
    height: 2px;
    bottom: 0px;
    margin: 0 auto;
    left: 17px;
    width: 13%;
    background-image: linear-gradient(90deg, #414042 5%, #2aa9e0 55%, #fff 99%);
}

.content_wrapper {
    display: flex;
    justify-content: flex-start;
    max-width: 1600px;
    margin: auto;
    margin-bottom: 5rem;
    padding: 30px 15px;
}

.product_tab_wrapper {
    display: flex;
    flex-flow: column;
    width: 440px;
    padding-right: 1.5rem;
}

.fitem_wrapper {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
    width: 77%;
    padding-left: 1.5rem;
}

.product_tab {
    /* border: 1px solid black; */
    margin: 0 0 0.2rem 0;
    text-align: center;
    background: #49a547;
    padding: 10px;
}

.product_tab_active {
    /* border: 1px solid black; */
    margin: 0 0 0.2rem 0;
    text-align: center;
    background: #95c93d;
    padding: 10px;

}

.product_tab:hover {
    cursor: pointer;
    background: #95c93d;
}

.tab-item-text {
    font-weight: 600;
    color: white;
    font-size: 16px;
}

.fItem {
    border: 1px solid rgb(20, 182, 197);
    padding: 0.5rem 1rem;
    margin: 1rem 0rem;
    text-align: center;
    background: rgb(222, 231, 91);
    width: 120px;
    height: 200px;
    margin: 8px;
}

.button_wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 20px;
}

.reset-password {
    font-size: 0.9rem;
    color: gray;
    font-style: italic;
    cursor: pointer;
}

/* The Modal (background) */
.modal_hide {
    /* display: none; */
    visibility: hidden;
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* The Modal (background) */
.modal_show {
    display: block;
    position: fixed;
    /* Stay in place */
    z-index: 15;
    /* Sit on top */
    padding-top: 65px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 75%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
    margin: auto;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

/* The Close Button */
.close {
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 0 16px;
}

.modal-body-cust {
    padding: 10px;
}

@media (max-width: 780px) {
    .content_wrapper {
        display: flex;
        flex-flow: column;
        justify-content: center;
        max-width: 1220px;
        /* width: 100%; */
        margin: auto;
        margin-bottom: 5rem;
        padding: 0 20px;
    }

    .product_tab_wrapper {
        width: 100%;
        padding-right: 0;
    }

    .fitem_wrapper {
        width: 100%;
        padding-left: 0;
        margin-top: 10px;
        flex-flow: column;
        align-items: center;
    }

    .product_tab {
        margin: 0 0 0.2rem 0;
    }

    .product_tab_active {
        margin: 0 0 0.2rem 0;
    }
}

@media (max-width: 438px) {
    .fitem_wrapper {
        justify-content: center;
    }
}