.rti-button {
    border-radius: 25px;
    cursor: pointer;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 18;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 20px;
    background-color: #49a547;
    -webkit-box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    width: 250px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    text-decoration: none;
}

.rti-button-mobile {
    border-radius: 25px;
    cursor: pointer;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 18;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: 20px;
    background-color: #49a547;
    -webkit-box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    width: 60px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    text-decoration: none;
}

.rti-button-img-mobile {
    width: 40px;
}

.rti-button-img {
    width: 40px;
}

.rti-button:hover {
    text-decoration: none;
    color: white
}