/* CARD */
.highlightcard_media_center {
  align-items: center;
}

.portfolio-media-header {
  margin-bottom: 0;
}

.media_heading_blue {
  color: #49a547;
  font-size: 18pt;
  margin-top: 0;
}

.footnotes-row {
  margin: 30px 10px 20px 10px;
}

.footnotes-para {
  font-size: 12pt;
}

.spotlight-card-wrapper {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 615px) {
  .footnotes-row {
    margin: 30px 20px 20px 20px;
  }

  .footnotes-para {
    margin-right: 20px;
    margin-left: 20px;
  }
}

/* SPOTLGIHT */
.highlights-row-container {
  margin-bottom: 9rem;
  padding: 60px;
}

.highlights_header {
  justify-content: center;
  text-transform: uppercase;
  margin-top: 7rem;
  margin-bottom: 2.5rem;
  text-align: center;
  flex-flow: column;
}

.heading_after {
  font-weight: 800;
  font-size: 2.3rem;
}

.heading_after::after {
  content: "";
  height: 10px;
  background-color: #49a547;
  display: block;
  width: 60px;
  margin: 0 auto;
  left: 0;
  right: 0;
  margin-top: 35px;
}

.highlights_content_row {
  margin-bottom: 25px;
  justify-content: space-between;
}

.tap-number {
  background-color: #49a547;
  padding: 15px;
}

.tap-number-numtext {
  font-size: 2.5rem;
  color: white;
  padding-right: 10px;
  font-weight: 600;
}

.tap-number-text {
  font-size: 1.6rem;
  color: white;
}

.tap-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 2.1rem;
}

.highlights_asof_date {
  font-size: 16pt;
}

.footnote-show {
  display: block !important;
}

.footnote-hide {
  display: none !important;
}

.footnote-container {
  padding: 0 20px;
}

.footnotes-title-span:hover {
  cursor: pointer;
}

@media only screen and (max-width: 615px) {
  .highlights-row-container {
    margin-bottom: 9rem;
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1538px) {
  .tap-number {
    width: 100%;
  }
}

@media only screen and (max-width: 415px) {
  .tap-number-text {
    display: block;
  }
}
