.fitem_item_wrapper {
    margin: 0 0.7rem 0.4rem 0;
}

.fitem_item_wrapper:hover {
    cursor: pointer;
}

.fitem_image_shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.fitem_image_shadow:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}