.socialmedia-content-container {
    display: flex;
}

.social-media-info-wrapper {
    width: 50%
}

.social-media-image-wrapper {
    width: 45%;
    padding-left: 55px;
}

@media only screen and (max-width: 1000px) {
    .socialmedia-content-container {
        flex-flow: column
    }

    .social-media-info-wrapper {
        width: 100%;
    }

    .social-media-image-wrapper {
        width: 45%;
        padding-left: 55px;
    }

    .social-media-image-wrapper {
        width: 100%;
        padding: 10px 50px 10px 50px;
    }
}

@media only screen and (max-width: 650px) {
    .social-media-image-wrapper {
        width: 100%;
        padding: 10px 0;
    }
}