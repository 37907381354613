.login-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 85px;
}

.login-header h3 {
  font-weight: 300;
  font-size: 20px;
}

.login-header p {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0;
}

.form-error {
  border: 1px solid #d44950;
  padding: 15px;
  background: #f8d7da;
  color: #d44950;
  margin: 5px 0;
  border-radius: 5px;
}

.form-success {
  border: 1px solid #3dca1a;
  padding: 15px;
  background: #98d689;
  color: #3dca1a;
  margin: 5px 0;
  border-radius: 5px;
}

.reset-pw-link {
  font-size: 20px;
  margin-top: 20px;
}

.register-here-link {
  font-size: 20px;
  text-decoration: none;
}

.register-here-p {
  font-size: 18px;
}

.register-here-link:hover {
  text-decoration: none;
  color: #95c93d;
}

.reset-pw-link:hover {
  cursor: pointer;
  color: #95c93d;
}

.login-lower-content-flex-container {
  display: flex;
}

.login-lower-title-wrapper {
  width: 50%;
  margin: 40px;
}

.login-lower-loginbox-wrapper {
  width: 50%;
  margin: 40px;
}

.login-input-box {
  margin-bottom: 10px;
  padding: 5px;
}

.investor-box-heading-1 {
  margin-bottom: 40px;
  font-weight: 400;
}

.investor-box-heading-2 {
  font-weight: 400;
  margin-bottom: 10px;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 4px 0;
  box-sizing: border-box;
  border: 1px solid #ababab;
  color: #414042;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus {
  border: 1px solid rgb(53, 53, 53);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #414042;
  border: none;
  color: white;
  padding: 8px 25px;
  text-decoration: none;
  margin: 6px 0;
  cursor: pointer;
  border-radius: 5px;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #49a547;
}

.remember-me-para {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

.remember-me-text {
  font-size: small;
  margin-left: 5px;
}

.advisor-submit-button {
  background-color: #414042;
  border: none;
  color: white;
  padding: 8px 25px;
  text-decoration: none;
  margin: 4px 0;
  cursor: pointer;
  border-radius: 5px;
}

.advisor-submit-button:hover {
  background-color: #49a547;
}

.login-page-heading {
  font-weight: 400;
}

.login-page-heading span {
  font-weight: 600;
}

.login-page-heading span:hover {
  cursor: pointer;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #49a547;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* *LOGIN FOOTER START */

.login-footer-wrapper {
  left: 0;
  bottom: 0;
  width: 100%;
}

.login-footer-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.login-footer-text-top {
  font-size: 18px;
  color: #7f7f7f;
  cursor: pointer;
}

.login-footer-text-bottom {
  color: #a7a7a7;
  font-style: italic;
  font-size: 0.8rem;
  text-align: center;
}

.login-footer-column {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 10px;
}

/* Modal Content */
.modal-content-cust-login {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  max-width: 1170px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 7px;
}

.close_button_bottom {
  padding: 10px 16px;
  background-color: #49a547;
  color: white;
  border-radius: 0 0 5px 5px;
  text-align: center;
}

.modal_close_button {
  background: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  color: #49a547;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

/* *LOGIN FOOTER END */

/* *PP/TOU start */
.margin_left {
  margin-left: 25px;
}

/* *PP/TOU end*/

.login-heading-negative-margin {
  margin-left: -40px;
}

.login-new-arrow {
  padding-bottom: 9px;
  margin-right: 5px;
}

@media only screen and (max-width: 965px) {
  .login-lower-content-flex-container {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .login-lower-title-wrapper {
    width: 75%;
  }

  .login-lower-loginbox-wrapper {
    width: 75%;
  }

  .modal-content-cust-login {
    width: 96%;
  }
}

@media only screen and (max-width: 765px) {
  .login-header {
    padding: 15px 15px;
  }
}

@media only screen and (max-width: 600px) {
  .login-header h3 {
    font-size: 12px;
  }

  .login-header p {
    font-size: 12px;
  }

  .login-header-text-wrapper {
    padding: 0 10px;
  }

  .sdcf-login-logo {
    width: 35%;
  }

  .login-lower-title-wrapper {
    width: 85%;
  }

  .login-lower-loginbox-wrapper {
    width: 85%;
  }
}

@media only screen and (max-width: 503px) {
  .login-heading-negative-margin {
    margin-left: 0;
  }
}

@media only screen and (min-height: 975px) {
  .login-footer-wrapper {
    position: fixed;
  }
}
