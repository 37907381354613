.targetprop-content-container {
    display: flex;
}

.targetprop-info-wrapper {
    width: 50%
}

.targetprop-img-wrapper {
    width: 50%
}

@media only screen and (max-width: 850px) {
    .targetprop-content-container {
        flex-flow: column
    }

    .targetprop-info-wrapper {
        width: 100%;
    }

    .targetprop-img-wrapper {
        width: 100%;
        margin: 30px 0;
    }
}

@media only screen and (max-width: 600px) {
    .targetprop-img-wrapper img {
        width: 100%;
    }
}