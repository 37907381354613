.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.d-block {
  display: block;
}

.d-i-block {
  display: inline-block;
}

.container-padding {
  padding: 30px 50px;
}

.info-para {
  line-height: 38px;
  font-size: 18px;
}

.hover:hover {
  cursor: pointer;
}


button:hover {
  cursor: pointer;
}

.section-title-gray {
  text-transform: uppercase;
  font-size: 40px;
  line-height: 1.3;
  font-weight: 600;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
  letter-spacing: 2px;
  text-align: center;
  font-weight: 800;
  color: #414042
}

.section-title-gray::after {
  content: "";
  display: inline-block;
  width: 70px;
  height: 10px;
  background: #49a547;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -35px;
}

.section-title-white {
  text-transform: uppercase;
  font-size: 40px;
  line-height: 1.3;
  font-weight: 600;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
  letter-spacing: 2px;
  text-align: center;
  font-weight: 800;
  color: white;
}

.section-title-white::after {
  content: "";
  display: inline-block;
  width: 70px;
  height: 10px;
  background: #414042;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -35px;
}

/* COLORS */
.black {
  color: #000000;
}

.white {
  color: #ffffff;
}

.d-green {
  color: #49a547;
}

.l-green {
  color: #95c93d;
}

.d-gray-1 {
  color: #6f7481;
}

.d-gray-2 {
  color: #777777;
}

.d-gray-3 {
  color: #7f7f7f;
}

.d-gray-4 {
  color: #414042;
}

.l-gray-1 {
  color: #bdbdbd;
}

.l-gray-2 {
  color: #c3c3c3;
}

.l-gray-3 {
  color: #cccccc;
}

.l-gray-4 {
  color: #e2e2e2;
}

.l-gray-5 {
  color: #eaeaea;
}

/* BACKGROUND-COLORS */
.black-bg {
  background-color: #000000;
}

.white-bg {
  background-color: #ffffff;
}

.d-green-bg {
  background-color: #49a547;
}

.l-green-bg {
  background-color: #95c93d;
}

.d-gray-1-bg {
  background-color: #6f7481;
}

.d-gray-2-bg {
  background-color: #777777;
}

.d-gray-3-bg {
  background-color: #7f7f7f;
}

.d-gray-4-bg {
  background-color: #414042;
}

.l-gray-1-bg {
  background-color: #bdbdbd;
}

.l-gray-2-bg {
  background-color: #c3c3c3;
}

.l-gray-3-bg {
  background-color: #cccccc;
}

.l-gray-4-bg {
  background-color: #e2e2e2;
}

.l-gray-5-bg {
  background-color: #eaeaea;
}

/* FONT-WEIGHTS */
.fw-1 {
  font-weight: 100;
}

.fw-2 {
  font-weight: 200;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

.fw-9 {
  font-weight: 900;
}

/* TEXT */

.uc {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.t-center {
  text-align: center;
}

.big-text {
  font-weight: 700;
  font-size: 27px;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 500px) {
  .container-padding {
    padding: 30px 20px;
  }
}